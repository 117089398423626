import { ITEMLIST, SUGGESTED_NEW_PAIRS } from 'actions/actionTypes';
import { put } from 'redux-saga/effects';
import { getRequest } from '_http';
import { action } from 'reduxHelpers';

// Gets all suggested pairs centers
function* fetchSuggestedPairListAsync({ isOwner, searchParams, sortParams, pageNo, pageSize }) {
  try {
    console.log('inside api call attribute groups');
    const _sortParams =
      sortParams && Object.keys(sortParams).length > 0
        ? encodeURIComponent(JSON.stringify(sortParams))
        : encodeURIComponent(JSON.stringify({ desc: ['submittedDate'] }));
    const _filter = searchParams
      ? encodeURIComponent(JSON.stringify(searchParams))
      : encodeURIComponent(JSON.stringify({}));

    const suggestedPairs = yield getRequest(
      `/brandconversion/conversion-pairs/requests?isOwner=${encodeURIComponent(
        isOwner
      )}&search-param=${_filter}&sort-params=${_sortParams}&current-page=${pageNo}&page-size=${pageSize}`
    );
    console.log('data', suggestedPairs);
    yield put({ type: SUGGESTED_NEW_PAIRS.SUCCESS, payload: { suggestedPairs } });
  } catch (error) {
    console.log('inside api call error ', error);
    yield put({ type: SUGGESTED_NEW_PAIRS.FAILURE });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: "COULDN'T FETCH SUGGESTED PAIR DETAILS"
      })
    );
  }
}

export { fetchSuggestedPairListAsync };
