import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  List,
  message,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table
} from 'antd';
import _ from 'lodash';
import * as actionCreators from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import openNotification from './Notification';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

const SuggestedNewPairModal = ({ isVisible, handleCancel }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  //selectors
  const suggestedPairs = useSelector(state => _.get(state.suggestedPairList, 'suggestedPairs', {}));
  const isSavingPair = useSelector(state => _.get(state.newPair, 'isSavingPair', false));
  const user = useSelector(state => _.get(state, 'user', []));
  const fetchingSuggestedPairs = useSelector(state => _.get(state.suggestedPairList, 'fetchingSuggestedPairs', false));
  const isUpdatingPairs = useSelector(state => _.get(state.updateSuggestedPair, 'isUpdatingPairs', false));
  const pairTypes = useSelector(state => _.get(state.pairTypes, 'pairTypes', []));

  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchParams, setSearchParams] = useState({});
  const [pairSort, setPairSort] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [suggestedPairRecords, setSuggestedPairRecords] = useState([]);
  const [reviewedPairs, setReviewedPairs] = useState([]);

  useEffect(() => {
    setPermissions(user?.data?.permissions);
  }, [user]);

  useEffect(() => {
    setSuggestedPairRecords(...suggestedPairRecords, suggestedPairs?.data?.data);
  }, [suggestedPairs?.data?.data]);

  useEffect(() => {
    setReviewedPairs([]);
    if (!isUpdatingPairs && permissions.length !== 0) {
      if (!permissions.includes('BRANDCONVERSIONAPP.EDIT')) {
        dispatch(
          actionCreators.fetchSuggestedPairList({
            isOwner: true,
            pageNo: currentPage - 1,
            pageSize: 10,
            sortParams: pairSort,
            searchParams: searchParams
          })
        );
      } else {
        dispatch(
          actionCreators.fetchSuggestedPairList({
            isOwner: false,
            pageNo: currentPage - 1,
            pageSize: 10,
            sortParams: pairSort,
            searchParams: searchParams
          })
        );
      }
    }
  }, [currentPage, searchParams, pairSort, isUpdatingPairs]);

  useEffect(() => {
    if (fetchingSuggestedPairs) {
      setSuggestedPairRecords([]);
      setReviewedPairs([]);
    }
  }, [fetchingSuggestedPairs]);

  const handleModalCancel = () => {
    handleCancel(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(dataIndex);
    setCurrentPage(1);
    console.log(selectedKeys[0] + '----' + dataIndex);
    let formattedDates = [];
    if ((dataIndex === 'submittedDate' && selectedKeys[0]) || (dataIndex === 'reviewedDate' && selectedKeys[0])) {
      formattedDates = [
        moment(selectedKeys[0][0]).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        moment(selectedKeys[0][1]).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      ];
      setSearchText(formattedDates);
      addPropertyOnFilters(dataIndex, formattedDates);
    } else {
      setSearchText(selectedKeys[0]);
      addPropertyOnFilters(dataIndex, selectedKeys[0]);
    }
  };

  const handleReset = (clearFilters, column) => {
    console.log(column);
    // Create a shallow copy of searchParams
    const updatedSearchParams = { ...searchParams };
    delete updatedSearchParams[column];
    clearFilters();
    setSearchText('');
    setSearchParams(updatedSearchParams);
  };

  // when new filters are appending filter object will be updated.
  const addPropertyOnFilters = (key, value) => {
    const currentSearchParams = { ...searchParams };
    // Add or update key-value pairs
    currentSearchParams[key] = value;
    // Update the state with the new searchParams object
    setSearchParams(currentSearchParams);
  };

  const getColumnSearchProps = (dataIndex, isDateColumn = false) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        {isDateColumn ? (
          // Date Range Filter UI
          <RangePicker
            value={selectedKeys[0] ? [moment(selectedKeys[0][0]), moment(selectedKeys[0][1])] : []}
            onChange={dates => {
              setSelectedKeys(dates ? [[dates[0].startOf('day'), dates[1].endOf('day')]] : []);
            }}
            style={{ marginBottom: 8, display: 'block' }}
          />
        ) : (
          // Text Search UI
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
        )}
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (isDateColumn) {
        const [start, end] = value;
        const recordDate = moment(record[dataIndex]);
        return recordDate.isBetween(start, end, null, '[]'); // inclusive of start and end dates
      }
      return record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '';
    }
  });

  const getFormattedBrands = () => {
    pairTypes.sort();
    const formatted = [];
    _.forEach(pairTypes, elem => {
      formatted.push({ text: elem.type, value: elem.type });
    });
    return formatted;
  };

  /**
   * Store all updatesd pair type records.
   * @param {*} newPairType
   * @param {*} record
   * @param {*} dataIndex
   */
  const handleCellChange = (newValue, record, dataIndex) => {
    record[dataIndex] = newValue; // Use bracket notation for dynamic keys
    console.log(`Updated ${dataIndex}:`, newValue);
    // Update the state holding the table data (assuming `suggestedPairRecords` is your data state)
    setSuggestedPairRecords(prevRecords => {
      return prevRecords.map(item => (item.key === record.key ? { ...item, [dataIndex]: newValue } : item));
    });

    // Create payload by keeping existing values from reviewedPairs and updating the relevant field
    setReviewedPairs(prevRecords => {
      return prevRecords.map(item => {
        if (item.requestId === record.key) {
          // Determine the new reason value: clear it if status is not "Rejected".
          return {
            ...item,
            status: dataIndex === 'status' ? newValue : item.status, // Update status if dataIndex is 'status'
            reason: dataIndex === 'rejectionReason' ? newValue : item.rejectionReason // Update reason if dataIndex is 'rejectionReason' or clear if status is changed to non-rejected
          };
        }
        // Return the unchanged item if it doesn't match the record
        return item;
      });
    });

    // Add a new record if the requestId does not exist
    setReviewedPairs(prevRecords => {
      const existingRecord = prevRecords.find(item => item.requestId === record.key);

      if (!existingRecord) {
        // If it's a new record, add it with appropriate status and reason
        return [
          ...prevRecords,
          {
            requestId: record.key,
            status: dataIndex === 'status' ? newValue : '',
            reason:
              dataIndex === 'status' && newValue !== 'Rejected' ? '' : dataIndex === 'rejectionReason' ? newValue : ''
          }
        ];
      }

      return prevRecords;
    });
    //Remove pairs reverted to "pending" status
    setReviewedPairs(prevRecords => prevRecords.filter(item => item.status !== 'Pending'));
    console.log(reviewedPairs);
  };

  const columns = [
    {
      title: 'From SUPC',
      dataIndex: 'fromSupc',
      ...getColumnSearchProps('fromSupc'),
      sorter: (a, b) => a.fromSupc.localeCompare(b.fromSupc),
      width: 50
    },
    {
      title: 'From Item Description',
      dataIndex: 'fromItemDescription',
      ...getColumnSearchProps('fromItemDescription'),
      sorter: (a, b) => a.fromItemDescription.localeCompare(b.fromItemDescription),
      width: 100
    },
    {
      title: 'To SUPC',
      dataIndex: 'toSupc',
      ...getColumnSearchProps('toSupc'),
      sorter: (a, b) => a.toSupc.localeCompare(b.toSupc),
      width: 50
    },
    {
      title: 'To Item Description',
      dataIndex: 'toItemDescription',
      ...getColumnSearchProps('toItemDescription'),
      sorter: (a, b) => a.toItemDescription.localeCompare(b.toItemDescription),
      width: 100
    },
    {
      title: 'From BC-IG-AG',
      dataIndex: 'fromBusinesscenter',
      ...getColumnSearchProps('fromBusinesscenter'),
      sorter: (a, b) => a.fromBusinesscenter.localeCompare(b.fromBusinesscenter),
      width: 100,
      render: (_, record) => {
        return `${record.fromBusinesscenter || ''} - ${record.fromItemgroup || ''} - ${record.fromAttributegroup ||
          ''}`;
      },
      hidden: !permissions.includes('BRANDCONVERSIONAPP.EDIT')
    },
    {
      title: 'To BC-IG-AG',
      dataIndex: 'toBusinesscenter',
      ...getColumnSearchProps('toBusinesscenter'),
      sorter: (a, b) => a.toBusinesscenter.localeCompare(b.toBusinesscenter),
      width: 100,
      render: (_, record) => {
        return `${record.toBusinesscenter || ''} - ${record.toItemgroup || ''} - ${record.toAttributegroup || ''}`;
      },
      hidden: !permissions.includes('BRANDCONVERSIONAPP.EDIT')
    },
    {
      title: 'From Brand Code',
      dataIndex: 'fromBrandCode',
      ...getColumnSearchProps('fromBrandCode'),
      sorter: (a, b) => a.fromBrandCode.localeCompare(b.fromBrandCode),
      width: 100,
      hidden: !permissions.includes('BRANDCONVERSIONAPP.EDIT')
    },
    {
      title: 'To Brand Code',
      dataIndex: 'toBrandCode',
      ...getColumnSearchProps('toBrandCode'),
      sorter: (a, b) => a.toBrandCode.localeCompare(b.toBrandCode),
      width: 100,
      hidden: !permissions.includes('BRANDCONVERSIONAPP.EDIT')
    },
    {
      title: 'Pair Type',
      dataIndex: 'pairType',
      sorter: (a, b) => a.pairType.localeCompare(b.pairType),
      width: 100,
      hidden: !permissions.includes('BRANDCONVERSIONAPP.EDIT'),
      filters: getFormattedBrands()
    },
    {
      title: 'Submitted By',
      dataIndex: 'submittedBy',
      ...getColumnSearchProps('submittedBy'),
      sorter: (a, b) => a.submittedBy.localeCompare(b.submittedBy),
      width: 100,
      hidden: !permissions.includes('BRANDCONVERSIONAPP.EDIT')
    },
    {
      title: 'Submitted Date',
      dataIndex: 'submittedDate',
      sorter: (a, b) => new Date(a.submittedDate) - new Date(b.submittedDate),
      width: 100,
      render: submittedDate => (submittedDate ? moment(submittedDate).format('MM/DD/YYYY h:mm:ss A') : ''),
      ...getColumnSearchProps('submittedDate', true)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      width: 100,
      hidden: permissions.includes('BRANDCONVERSIONAPP.EDIT'),
      filters: permissions.includes('BRANDCONVERSIONAPP.EDIT')
        ? [{ text: 'Pending', value: 'Pending' }]
        : [
            { text: 'Pending', value: 'Pending' },
            { text: 'Rejected', value: 'Rejected' }
          ]
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      width: 100,
      hidden: !permissions.includes('BRANDCONVERSIONAPP.EDIT'),
      editable: true,
      render: (text, record) => (
        <Select
          value={text}
          style={{ width: 120 }}
          onChange={value => {
            handleCellChange(value, record, 'status');
            // Trigger validation when status changes to 'Rejected'
            if (value === 'Rejected') {
              form.validateFields([`rejectionReason-${record.key}`]);
            }
          }}
          options={[
            { label: 'Pending', value: 'Pending' },
            { label: 'Rejected', value: 'Rejected' },
            { label: 'Approved', value: 'Approved' }
          ]}
        />
      ),
      filters: permissions.includes('BRANDCONVERSIONAPP.EDIT')
        ? [{ text: 'Pending', value: 'Pending' }]
        : [
            { text: 'Pending', value: 'Pending' },
            { text: 'Rejected', value: 'Rejected' }
          ]
    },
    {
      title: 'Rejection Reason',
      dataIndex: 'rejectionReason',
      ...getColumnSearchProps('rejectionReason'),
      sorter: (a, b) =>
        a.rejectionReason && b.rejectionReason ? a.rejectionReason.localeCompare(b.rejectionReason) : 0,
      width: 100,
      hidden: permissions.includes('BRANDCONVERSIONAPP.EDIT')
    },
    {
      title: 'Rejection Reason',
      dataIndex: 'rejectionReason',
      ...getColumnSearchProps('rejectionReason'),
      sorter: (a, b) =>
        a.rejectionReason && b.rejectionReason ? a.rejectionReason.localeCompare(b.rejectionReason) : 0,
      width: 100,
      hidden: !permissions.includes('BRANDCONVERSIONAPP.EDIT'),
      render: (text, record) =>
        record.status === 'Rejected' ? (
          <Form.Item
            name={`rejectionReason-${record.key}`} // Unique key for each input
            rules={[{ required: true, message: 'Reason is required.' }]}
            style={{ margin: 0 }}
          >
            <Input
              value={record.rejectionReason}
              onChange={e => {
                handleCellChange(e.target.value, record, 'rejectionReason');
                form.validateFields([`rejectionReason-${record.key}`]); // Trigger validation on change
              }}
              onBlur={() => {
                form.validateFields([`rejectionReason-${record.key}`]); // Trigger validation on blur
              }}
              placeholder="Enter rejection reason"
            />
          </Form.Item>
        ) : (
          text // Display the reason text when not editing
        )
    },
    {
      title: 'Reviewed By',
      dataIndex: 'reviewedBy',
      ...getColumnSearchProps('reviewedBy'),
      sorter: (a, b) => (a.reviewedBy && b.reviewedBy ? a.reviewedBy.localeCompare(b.reviewedBy) : 0),
      width: 100,
      hidden: permissions.includes('BRANDCONVERSIONAPP.EDIT')
    },
    {
      title: 'Reviewed Date',
      dataIndex: 'reviewedDate',
      sorter: (a, b) => (a.reviewedDate && b.reviewedDate ? new Date(a.reviewedDate) - new Date(b.reviewedDate) : 0),
      width: 100,
      render: reviewedDate => (reviewedDate ? moment(reviewedDate).format('MM/DD/YYYY h:mm:ss A') : ''),
      hidden: permissions.includes('BRANDCONVERSIONAPP.EDIT'),
      ...getColumnSearchProps('reviewedDate', true)
    },
    {
      title: 'Reviewer Email',
      dataIndex: 'reviewerEmail',
      ...getColumnSearchProps('reviewerEmail'),
      sorter: (a, b) => (a.reviewerEmail && b.reviewerEmail ? a.reviewerEmail.localeCompare(b.reviewerEmail) : 0),
      width: 100,
      hidden: permissions.includes('BRANDCONVERSIONAPP.EDIT')
    }
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    if (reviewedPairs.length !== 0) {
      message.error('You have unsaved changes in current page.');
      return;
    }
    if (extra.action === 'sort') {
      if (sorter.order === 'ascend') {
        setPairSort({ asc: [sorter.field] });
      } else {
        setPairSort({ desc: [sorter.field] });
      }
    }
    if (extra.action === 'paginate') {
      setCurrentPage(pagination?.current);
    }
    if (extra.action === 'filter') {
      Object.entries(filters).forEach(([key, value]) => {
        if (key === 'status' || key === 'pairType') {
          setCurrentPage(1);
          addPropertyOnFilters(key, value);
        }
      });
    }
  };

  // Function to handle form submission
  const handleSubmit = () => {
    form
      .validateFields()
      .then(values => {
        // All fields are valid, proceed with form submission
        console.log('Form Values:', values);
        console.log('Form Values:', suggestedPairRecords);
        dispatch(
          actionCreators.updateSuggestedPairList({
            payload: reviewedPairs
          })
        );
      })
      .catch(errorInfo => {
        // Handle validation failure
        console.log('Validation Failed:', errorInfo);
        message.error('Please fix the errors before submitting.');
      });
  };

  return (
    <>
      {/* Modal component - Do Not Convert Brand List */}
      <Modal
        title="Suggested New Conversion Pairs"
        visible={isVisible}
        onCancel={handleModalCancel}
        footer={null}
        width={'80%'}
      >
        {/* Use Spin component to show loading indicator */}
        <Spin spinning={fetchingSuggestedPairs}>
          {/* Modal content */}
          <Form form={form} component={false}>
            <Table
              columns={columns.filter(col => !col.hidden)}
              dataSource={suggestedPairRecords}
              pagination={{
                total: suggestedPairs?.data?.total, // Provide the total number of items
                current: currentPage,
                pageSizeOptions: [],
                showSizeChanger: false,
                pageSize: 10
              }}
              scroll={{ x: 'max-content' }}
              onChange={onChange}
              className="sgt-pairs"
            />
            <Row justify="end" gutter={16} style={{ marginTop: 16 }}>
              <Col>
                <Button type="primary" disabled={reviewedPairs.length === 0} onClick={handleSubmit}>
                  Submit
                </Button>
              </Col>
              <Col>
                <Button key="clear" onClick={handleModalCancel}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default SuggestedNewPairModal;
