import { action } from 'reduxHelpers';
import {
  USER,
  ITEMLIST,
  BUSINESS_CENTERS,
  ITEM_GROUPS,
  ATTRIBUTE_GROUPS,
  PAIR_TYPES,
  FILTER_PARAMS,
  DO_NOT_CONVERT_BRANDS,
  DO_NOT_CONVERT_BRANDS_DELETE,
  ALL_BRANDS,
  DO_NOT_CONVERT_BRANDS_ADD,
  DISTINCT_SUPC,
  NEW_PAIR,
  ITEM_CHANGES,
  COLUMN_FILTERS,
  COLUMN_FILTERS_REMOVE,
  COLUMN_SORTS,
  COLUMN_SORTS_REMOVE,
  VALID_ATTRIBUTES,
  VALIDATE_PAIR,
  QUALIFIERS,
  MATCH_QUALIFIERS,
  SUGGESTED_NEW_PAIRS,
  UPDATE_SUGGESTED_NEW_PAIRS
} from './actionTypes';

const loadUserDetails = userId => action(USER.REQUEST, { userId });
// brand conversion item list..
const fetchItemList = data => action(ITEMLIST.REQUEST, { ...data });

// brand conversion business center list..
const fetchBusinesscenters = data => action(BUSINESS_CENTERS.REQUEST);
// brand conversion item group list..
const fetchItemGroups = data => action(ITEM_GROUPS.REQUEST, { ...data });
// brand conversion attribute group list..
const fetchAttributeGroups = data => action(ATTRIBUTE_GROUPS.REQUEST, { ...data });
// get all pair types..
const fetchAllPairTypes = data => action(PAIR_TYPES.REQUEST);
// save all filter params..
const saveFilterParams = data => action(FILTER_PARAMS.REQUEST, { ...data });
// get all do not convert brands..
const getAllDoNotConvertBrands = data => action(DO_NOT_CONVERT_BRANDS.REQUEST, { ...data });
// delete selected do not convert brands..
const deleteSelectedDoNotConvertBrands = data => action(DO_NOT_CONVERT_BRANDS_DELETE.REQUEST, { ...data });
// get all brands..
const getAllBrands = data => action(ALL_BRANDS.REQUEST, { ...data });
// add add do not convert brands..
const addSelectedDoNotConvertBrands = data => action(DO_NOT_CONVERT_BRANDS_ADD.REQUEST, { ...data });
// distinct supc list..
const fetchDistinctSupcList = data => action(DISTINCT_SUPC.REQUEST, { ...data });

// save new pair details..
const saveNewPairMapping = data => action(NEW_PAIR.REQUEST, { ...data });

// save item changes details..
const saveItemChanges = data => action(ITEM_CHANGES.REQUEST, { ...data });

// save main table column filters changes details..
const saveColumnFilters = data => action(COLUMN_FILTERS.REQUEST, { ...data });
// reset main table column filters changes details..
const resetColumnFilters = data => action(COLUMN_FILTERS_REMOVE.REQUEST, { ...data });

// save main table column filters changes details..
const saveColumnSorts = data => action(COLUMN_SORTS.REQUEST, { ...data });
// reset main table column filters changes details..
const resetColumnSorts = data => action(COLUMN_SORTS_REMOVE.REQUEST, { ...data });
// get VALID ATTRIBUTES..
const getValidAttributes = data => action(VALID_ATTRIBUTES.REQUEST, { ...data });
// get VALIDDATE NEW PAIR..
const validateNewPair = data => action(VALIDATE_PAIR.REQUEST, { ...data });
// get all qualifier details..
const fetchQualifiers = data => action(QUALIFIERS.REQUEST, { ...data });

// get all MATCHING qualifier details..
const fetchMatchQualifiers = data => action(MATCH_QUALIFIERS.REQUEST, { ...data });
// brand conversion item list..
const fetchSuggestedPairList = data => action(SUGGESTED_NEW_PAIRS.REQUEST, { ...data });
//update pair list..
const updateSuggestedPairList = data => action(UPDATE_SUGGESTED_NEW_PAIRS.REQUEST, { ...data });
export {
  loadUserDetails,
  fetchItemList,
  fetchBusinesscenters,
  fetchItemGroups,
  fetchAttributeGroups,
  fetchAllPairTypes,
  saveFilterParams,
  getAllDoNotConvertBrands,
  deleteSelectedDoNotConvertBrands,
  getAllBrands,
  addSelectedDoNotConvertBrands,
  fetchDistinctSupcList,
  saveNewPairMapping,
  saveItemChanges,
  saveColumnFilters,
  resetColumnFilters,
  saveColumnSorts,
  resetColumnSorts,
  getValidAttributes,
  validateNewPair,
  fetchQualifiers,
  fetchMatchQualifiers,
  fetchSuggestedPairList,
  updateSuggestedPairList
};
