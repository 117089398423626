import initialState from 'store/initialState';
import { UPDATE_SUGGESTED_NEW_PAIRS } from 'actions/actionTypes';

const updateSuggestedPairReducer = (state = initialState.updateSuggestedPairs, action) => {
  switch (action.type) {
    case UPDATE_SUGGESTED_NEW_PAIRS.REQUEST:
      return { ...state, isUpdatingPairs: true };
    case UPDATE_SUGGESTED_NEW_PAIRS.SUCCESS:
      return {
        ...state,
        isUpdatingPairs: false,
        updatedSuggestedPairs: { ...state.updatedSuggestedPairs, updatedSuggestedPairs: action.payload.response.data }
      };
    case UPDATE_SUGGESTED_NEW_PAIRS.FAILURE:
      return { ...state, isUpdatingPairs: true};
    default:
      return state;
  }
};

export default updateSuggestedPairReducer;
