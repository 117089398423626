import initialState from 'store/initialState';
import { ITEMLIST, SUGGESTED_NEW_PAIRS } from 'actions/actionTypes';

const suggestedPairList = (state = initialState.suggestedPairs, action) => {
  switch (action.type) {
    case SUGGESTED_NEW_PAIRS.REQUEST:
      return { ...state, fetchingSuggestedPairs: true };
    case SUGGESTED_NEW_PAIRS.SUCCESS:
      return {
        ...state,
        fetchingSuggestedPairs: false,
        suggestedPairs: action.payload.suggestedPairs.data
      };
    case SUGGESTED_NEW_PAIRS.FAILURE:
      return { ...state, fetchingSuggestedPairs: false };
    default:
      return state;
  }
};

export default suggestedPairList;
